import { useState } from "react";

const App: React.FC = () => {
  const [user, setUser] = useState<string>("sk");
  const [type, setType] = useState<string>("feat");
  const [entry, setEntry] = useState<string>("");

  const [helpText, setHelpText] = useState<string>("Cliquez pour copier dans le presse-papier.");

  const onClick = () => {
    if (!isValid(user, type, entry)) return;
    navigator.clipboard.writeText(generateMrSlug(user, type, entry));
    setHelpText("🎉 Copié !");
    setTimeout(() => {
      setHelpText("Cliquez pour copier dans le presse-papier.");
    }, 1000);
  };

  return (
    <div className="w-screen h-screen bg-slate-100">
      <div className="w-fit mx-auto pt-20">
        <input
          type="text"
          onChange={(e) => setUser(e.target.value)}
          defaultValue={user}
          className="w-10 text-center h-10 rounded-l-lg bg-white border-2 border-slate-300 focus:outline-none focus-visible:outline-none text-slate-500 px-2"
        />
        <select
          onChange={(e) => setType(e.target.value)}
          className="h-10 text-center w-20 bg-white border-t-2 border-b-2 border-slate-300 focus:outline-none focus-visible:outline-none text-slate-500"
        >
          <option value="feat">feat</option>
          <option value="fix">fix</option>
          <option value="refacto">refacto</option>
        </select>
        <input
          type="text"
          onChange={(e) => setEntry(e.target.value)}
          className="w-[300px] h-10 rounded-r-lg bg-white border-2 border-slate-300 focus:outline-none focus-visible:outline-none text-slate-500 px-2"
        />
      </div>
      <div className="pt-10">
        <div
          className={
            (!isValid(user, type, entry) ? "" : "cursor-pointer") +
            " rounded-lg bg-white w-fit px-8 py-2 mx-auto text-slate-500 border-2 border-slate-300 h-10 select-none"
          }
          onClick={onClick}
        >
          {!isValid(user, type, entry) ? (
            "Format non valide"
          ) : (
            <>
              <div className="cursor-pointer text-center">{generateMrSlug(user, type, entry)}</div>
              <p className="text-xs text-center pt-2" id="help-text">
                {helpText}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default App;

const generateMrSlug = (user: string, type: string, entry: string) => {
  return (
    user +
    "/" +
    type +
    "/" +
    entry
      .replaceAll(" ", "-")
      .replaceAll("/", "")
      .replaceAll('"', "")
      .replaceAll(":", "")
      .replaceAll("?", "")
      .replaceAll("!", "")
      .replaceAll(".", "")
      .replaceAll("=", "")
      .replaceAll("`", "")
      .replaceAll(/-+/g, "-")
      .replaceAll(/-+$/g, "")
      .toLowerCase()
      .normalize("NFD")
      .replaceAll(/[\u0300-\u036f]/g, "")
      .split("-")
      .filter((item) => item.length > 2 || item.match(/^[0-9]+$/) != null)
      .filter((item) => !["and", "the"].includes(item))
      .join("-")
  );
};

const isValid = (user: string, type: string, entry: string) => {
  return user && type && entry;
};
